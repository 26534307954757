import { Routes } from '@angular/router';
import { agencyCommissionsRoutes } from './agency-commissions/agency-commissions.routes';
import { agencyPolicyRoutesRoutes } from './agency-policy-updates/agency-policy-updates.routes';

export const agencyRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        '../../shared/components/theme/theme-sidebar/theme-sidebar.component'
      ).then(c => c.ThemeSidebarComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./agency-dashboard/agency-dashboard.component').then(
            c => c.AgencyDashboardComponent
          ),
      },
      {
        path: 'statistics',
        loadComponent: () =>
          import('./agency-statistics/agency-statistics.component').then(
            c => c.AgencyStatisticsComponent
          ),
      },
      {
        path: 'statistics/ltv',
        loadComponent: () =>
          import('./agency-statistic-ltv/agency-statistic-ltv.component').then(
            c => c.AgencyStatisticLtvComponent
          ),
      },
      {
        path: 'commissions',
        children: agencyCommissionsRoutes,
      },
      {
        path: 'policy-update',
        children: agencyPolicyRoutesRoutes,
      },
    ],
  },
];
