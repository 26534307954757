import { Routes } from "@angular/router";

export const agentRoutes: Routes = [
    {
        path: '', loadComponent: () => import('../../shared/components/theme/theme-sidebar/theme-sidebar.component').then(c => c.ThemeSidebarComponent), children: [
            {
                path: '',
                loadComponent: () => import('./agent-dashboard/agent-dashboard.component').then(c => c.AgentDashboardComponent)
            },
            {
                path: 'statistics',
                loadComponent: () => import('./agent-statistics/agent-statistics-overview.component').then(c => c.AgentStatisticsOverviewComponent)
            }
        ]
    },
]