import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CommissionActions } from './commission.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Commission } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class CommissionEffects {
  loadCommissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionActions.loadCommissions),
      switchMap(() => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'commissions'),
            snapshot => {
              const commissions = snapshot.docs.map(doc =>
                Commission.fromJSON({ ...doc.data(), id: doc.id })
              );
              subscriber.next(
                CommissionActions.loadCommissionsSuccess({ commissions })
              );
            },
            error => {
              subscriber.next(
                CommissionActions.loadCommissionsFailure({ error })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Commission API] Load Commissions Error', error })
          )
        );
      })
    );
  });

  loadCommission$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionActions.loadCommission),
      switchMap(({ commissionId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'commissions', commissionId),
            snapshot => {
              const commission = Commission.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(
                CommissionActions.loadCommissionSuccess({ commission })
              );
            },
            error => {
              subscriber.next(
                CommissionActions.loadCommissionFailure({ error })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addCommission$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionActions.addCommission),
      mergeMap(async ({ commission }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'commissions'),
            commission
          );
          return CommissionActions.addCommissionSuccess({
            commission: Commission.fromJSON({ ...commission, id: docRef.id }),
          }); // return new commission with id
        } catch (error) {
          return CommissionActions.addCommissionFailure({ error });
        }
      })
    );
  });

  removeCommission$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionActions.removeCommission),
      mergeMap(async ({ commissionId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'commissions', commissionId));
          return CommissionActions.removeCommissionSuccess({ commissionId }); // return removed commission's id
        } catch (error) {
          return CommissionActions.removeCommissionFailure({ error });
        }
      })
    );
  });

  updateCommission$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommissionActions.updateCommission),
      mergeMap(async ({ commissionId, commission }) => {
        try {
          await setDoc(
            doc(this.firestore, 'commissions', commissionId),
            commission,
            {
              merge: true,
            }
          );
          return CommissionActions.updateCommissionSuccess({
            commissionId,
            commission,
          }); // return updated commission's id and changes
        } catch (error) {
          return CommissionActions.updateCommissionFailure({ error });
        }
      })
    );
  });

  constructor(private actions$: Actions, private firestore: Firestore) {}
}
