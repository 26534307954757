import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import packageJson from '../../../../../../package.json';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { UserType } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-theme-dev',
  templateUrl: './theme-dev.component.html',
  styleUrls: ['./theme-dev.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class ThemeDevComponent implements OnInit {
  production = environment.production;
  demo = environment.isDemo;
  version = environment.production
    ? packageJson.version
    : environment.devVersion;

  user: any = null;

  UserType = UserType;

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (!environment.production) {
      this.authService.user$.subscribe(user => {
        this.user = user;
        this.cdr.detectChanges();
      });
    }
  }
}
