import { Routes } from '@angular/router';

export const payoutRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        '../../shared/components/theme/theme-sidebar/theme-sidebar.component'
      ).then(c => c.ThemeSidebarComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./payouts-overview/payouts-overview.component').then(
            c => c.PayoutsOverviewComponent
          ),
      },
      {
        path: 'report',
        loadComponent: () =>
          import('./payouts-report/payouts-report.component').then(
            c => c.PayoutsReportComponent
          ),
      },
    ],
  },
];
