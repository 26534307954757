import { Routes } from '@angular/router';

export const employeeRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        '../../shared/components/theme/theme-sidebar/theme-sidebar.component'
      ).then(c => c.ThemeSidebarComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./employee-dashboard/employee-dashboard.component').then(
            c => c.EmployeeDashboardComponent
          ),
      },
    ],
  },
];
