<router-outlet></router-outlet>

<ng-container *ngIf="!production && !demo">
  <div class="h-12"></div>
  <div class="fixed bottom-0 left-0 right-0 px-4 pb-4">
    <div
      class="rounded-full py-2 px-6 grid md:grid-cols-3 grid-cols-2"
      [class.bg-secondary]="user?.type === UserType.CONSUMER"
      [class.bg-accent]="user?.type === UserType.AGENT"
      [class.bg-success]="user?.type === UserType.LEADSELLER"
      [class.bg-primary]="!user || !user.type || user.type === UserType.AGENCY"
      [class.bg-purple-700]="user?.type === UserType.CARRIER"
      [class.bg-gray-700]="user?.type === UserType.AUTHORITY">
      <h5 class="w-full mb-0 text-white md:order-1 order-2">dev environment</h5>
      <h5
        class="w-full mb-0 text-white text-center md:col-span-1 col-span-2 md:order-2 order-1"
        *ngIf="user">
        Logged in
        <span *ngIf="user?.type === UserType.CONSUMER"> as Consumer </span>
        <span *ngIf="user?.type === UserType.AGENT"> as Agent </span>
        <span *ngIf="user?.type === UserType.LEADSELLER">
          as Marketing Vendor
        </span>
        <span *ngIf="user?.type === UserType.CARRIER">
          as Insurance Carrier
        </span>
        <span *ngIf="user?.type === UserType.AGENCY"> as Agency </span>
        <span *ngIf="user?.type === UserType.AUTHORITY"> as CMS </span>
      </h5>
      <h5 class="w-full mb-0 text-white text-right order-3">
        {{ version }}
      </h5>
    </div>
  </div>
</ng-container>
