import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Commission } from 'src/app/shared/models';

export const CommissionActions = createActionGroup({
  source: 'Commission',
  events: {
    'Load Commissions': emptyProps(),
    'Load Commissions Success': props<{ commissions: Commission[] }>(),
    'Load Commissions Failure': props<{ error: any }>(),
    'Load Commission': props<{ commissionId: string }>(),
    'Load Commission Success': props<{ commission: Commission }>(),
    'Load Commission Failure': props<{ error: any }>(),
    'Add Commission': props<{ commission: Commission }>(),
    'Add Commission Success': props<{ commission: Commission }>(),
    'Add Commission Failure': props<{ error: any }>(),
    'Remove Commission': props<{ commissionId: string }>(),
    'Remove Commission Success': props<{ commissionId: string }>(),
    'Remove Commission Failure': props<{ error: any }>(),
    'Update Commission': props<{
      commissionId: string;
      commission: Partial<Commission>;
    }>(),
    'Update Commission Success': props<{
      commissionId: string;
      commission: Partial<Commission>;
    }>(),
    'Update Commission Failure': props<{ error: any }>(),
  },
});
