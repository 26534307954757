<router-outlet></router-outlet>
<div
  *ngIf="version"
  style="
    position: fixed;
    right: 24px;
    bottom: 0;
    z-index: 10;
    font-size: 4pt;
    color: gray;
  ">
  {{ version }}
</div>

@if (showModals$ | async; as showModals) {
  @for (showModal of showModals; track showModal) {
    @if (showModal.show) {
      <app-modal-container [showModal]="showModal"></app-modal-container>
    }
  }
}
