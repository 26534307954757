import { Routes } from '@angular/router';

export const agencyPolicyRoutesRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./policy-update-overview/policy-update-overview.component').then(
        c => c.PolicyUpdateOverviewComponent
      ),
  },
];
