import { createFeature, createReducer, on } from '@ngrx/store';
import { Commission } from 'src/app/shared/models';
import { CommissionActions } from './commission.actions';

export const commissionFeatureKey = 'commission';

export interface CommissionState {
  commissions: Commission[];
  selectedCommission: Commission | null;
  loading: boolean;
  error: any;
}

export const initialState: CommissionState = {
  commissions: [],
  selectedCommission: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    CommissionActions.loadCommissions,
    (state): CommissionState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CommissionActions.loadCommissionsSuccess,
    (state, { commissions }): CommissionState => ({
      ...state,
      commissions,
      loading: false,
      error: null,
    })
  ),
  on(
    CommissionActions.loadCommissionsFailure,
    (state, { error }): CommissionState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CommissionActions.loadCommission,
    (state): CommissionState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CommissionActions.loadCommissionSuccess,
    (state, { commission }): CommissionState => ({
      ...state,
      commissions: [commission],
      selectedCommission: commission,
      loading: false,
      error: null,
    })
  ),
  on(
    CommissionActions.loadCommissionFailure,
    (state, { error }): CommissionState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CommissionActions.addCommission,
    (state): CommissionState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CommissionActions.addCommissionSuccess,
    (state, { commission }): CommissionState => ({
      ...state,

      commissions: [...state.commissions, commission],

      loading: false,

      error: null,
    })
  ),
  on(
    CommissionActions.addCommissionFailure,
    (state, { error }): CommissionState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CommissionActions.removeCommission,
    (state): CommissionState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CommissionActions.removeCommissionSuccess,
    (state, { commissionId }): CommissionState => ({
      ...state,
      commissions: state.commissions.filter(
        commission => commission.id !== commissionId
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    CommissionActions.removeCommissionFailure,
    (state, { error }): CommissionState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CommissionActions.updateCommission,
    (state): CommissionState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CommissionActions.updateCommissionSuccess,
    (state, { commission }): CommissionState => ({
      ...state,
      commissions: state.commissions.map(item =>
        // item.id === commission.id ? commission : item
        item.id === commission.id ? { ...item, ...commission } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    CommissionActions.updateCommissionFailure,
    (state, { error }): CommissionState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const commissionFeature = createFeature({
  name: commissionFeatureKey,
  reducer,
});
