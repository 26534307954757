import { Routes } from "@angular/router";

export const dashboardRoutes: Routes = [
    {
        path: '', loadComponent: () => import('../../shared/components/theme/theme-sidebar/theme-sidebar.component').then(c => c.ThemeSidebarComponent), children: [
            {
                path: '',
                loadComponent: () => import('./dashboard-redirect/dashboard-redirect.component').then(c => c.DashboardRedirectComponent)
            }
        ]
    },
]