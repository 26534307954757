import { Component, Input, ViewChild } from '@angular/core';
import {
  ModalContainerService,
  ShowModalComponents,
  ShowModalContainer,
} from 'src/app/shared/services/modal-container.service';
import { PolicyUpdateEditModalComponent } from '../../policy-update/policy-update-table/policy-update-edit-modal/policy-update-edit-modal.component';
import { CommissionsEditModalComponent } from '../../commissions/commissions-table/commissions-edit-modal/commissions-edit-modal.component';
import { EnrollmentModalComponent } from '../../enrollment/enrollment-modal/enrollment-modal.component';
import { PayoutsDetailModalComponent } from '../../payouts/payouts-table/payouts-detail-modal/payouts-detail-modal.component';
import { AsyncPipe } from '@angular/common';
import { PayoutsOverviewModalComponent } from '../../payouts/payouts-overview-modal/payouts-overview-modal.component';

@Component({
  selector: 'app-modal-container',
  standalone: true,
  imports: [
    AsyncPipe,
    CommissionsEditModalComponent,
    EnrollmentModalComponent,
    PayoutsDetailModalComponent,
    PayoutsOverviewModalComponent,
    PolicyUpdateEditModalComponent,
  ],
  templateUrl: './modal-container.component.html',
  styleUrl: './modal-container.component.scss',
})
export class ModalContainerComponent {
  @Input() showModal?: ShowModalContainer;

  @ViewChild('commissionsEditModal')
  commissionsEditModal?: CommissionsEditModalComponent;

  @ViewChild('enrollmentModal') enrollmentModal?: EnrollmentModalComponent;

  @ViewChild('payoutsDetailModal')
  payoutsDetailModal?: PayoutsDetailModalComponent;

  @ViewChild('policyUpdatesEditModal')
  policyUpdatesEditModal?: PolicyUpdateEditModalComponent;

  ShowModalComponents = ShowModalComponents;

  constructor(private modalContainerService: ModalContainerService) {}

  fireEvent(eventName: string, data: any) {
    // this.modalContainerService.currentEvents$?.next({ eventName, data });
    if (this.showModal?.id) {
      this.modalContainerService.subscriptionMap[this.showModal.id]?.next({
        eventName,
        data,
      });
    }
  }

  modalDismissed(component: ShowModalComponents, event: any) {
    console.log('modalDismissed', {
      component,
      event,
      showModal: this.showModal,
    });
    if (this.showModal?.id) {
      this.modalContainerService.close(this.showModal.id);
    }
  }
}
