import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ThemeDemoBorderComponent } from './shared/components/theme/theme-demo-border/theme-demo-border.component';
import { ThemeDevComponent } from './shared/components/theme/theme-dev/theme-dev.component';
import { agencyRoutes } from './components/agency/agency.routes';
import { agentRoutes } from './components/agent/agent.routes';
import { dashboardRoutes } from './components/dashboard/dashboard.routes';
import { payoutRoutes } from './components/payouts/payout.routes';
import { employeeRoutes } from './components/employee/employee.routes';
import { bookOfBusinessRoutes } from './components/book-of-business/book-of-business.routes';

export const routes: Routes = [
  {
    path: '',
    component: environment.isDemo
      ? ThemeDemoBorderComponent
      : ThemeDevComponent,
    children: [
      {
        path: '',
        children: dashboardRoutes,
      },
      {
        path: 'sso',
        loadComponent: () =>
          import('./components/auth/sso/sso.component').then(
            m => m.SsoComponent
          ),
      },
      {
        path: 'agency',
        children: agencyRoutes,
      },
      {
        path: 'agent',
        children: agentRoutes,
      },
      {
        path: 'book-of-business',
        loadChildren: () =>
          import('./components/book-of-business/book-of-business.routes').then(
            r => r.bookOfBusinessRoutes
          ),
      },
      {
        path: 'employee',
        children: employeeRoutes,
      },
      {
        path: 'payouts',
        children: payoutRoutes,
      },
    ],
  },
];
