{
  "name": "enrollhere-commissions",
  "version": "0.1.11",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:enrollhere-commissions": "node dist/enrollhere-commissions/server/server.mjs",
    "replace-timestamp": "node ./replace.build.js",
    "rebase-demo": "git checkout demo;git rebase dev",
    "deploy-major": "npm version major && git add . && git push --tags && git push origin main:main",
    "deploy-minor": "npm version minor && git add . && git push --tags && git push origin main:main",
    "deploy": "npm version patch && git add . && git push --tags && git push origin main:main",
    "deploy:dev": "firebase deploy --only hosting:enrollhere-commissions --project enroll-here-dev",
    "deploy:prod:temp": "ng build --c production &&  firebase deploy --only hosting:enrollhere-commissions --project enroll-here --config firebase.temp.json",
    "deploy:demo:temp": "mv ./src/environments/environment.ts ./src/environments/environment.prod.ts  && cp ./src/environments/environment.demo.ts ./src/environments/environment.ts && ng build --c production &&  firebase deploy --only hosting:enrollhere-commissions --project enroll-here-demo --config firebase.temp.json && mv ./src/environments/environment.prod.ts ./src/environments/environment.ts",
    "deploy:dev:temp": "mv ./src/environments/environment.ts ./src/environments/environment.prod.ts  && cp ./src/environments/environment.development.ts ./src/environments/environment.ts && ng build --c production &&  firebase deploy --only hosting:enrollhere-commissions --project enroll-here-dev --config firebase.temp.json && mv ./src/environments/environment.prod.ts ./src/environments/environment.ts",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org enrollhere --project enrollhere-commissions ./dist/enrollhere-commissions/browser && sentry-cli sourcemaps upload --org enrollhere --project enrollhere-commissions ./dist/enrollhere-commissions/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.12",
    "@angular/common": "^17.3.12",
    "@angular/compiler": "^17.3.12",
    "@angular/core": "^17.3.12",
    "@angular/fire": "^17.1.0",
    "@angular/forms": "^17.3.12",
    "@angular/platform-browser": "^17.3.12",
    "@angular/platform-browser-dynamic": "^17.3.12",
    "@angular/platform-server": "^17.3.12",
    "@angular/router": "^17.3.12",
    "@angular/service-worker": "^17.3.12",
    "@angular/ssr": "^17.3.9",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/pro-regular-svg-icons": "^6.5.1",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@sentry/angular": "^8.30.0",
    "@sentry/cli": "^2.36.1",
    "@swimlane/ngx-charts": "^20.4.1",
    "express": "^4.21.0",
    "lottie-web": "^5.12.2",
    "ngx-cookie-service": "^17.1.0",
    "ngx-infinite-scroll": "^17.0.1",
    "prettier": "^3.3.3",
    "replace-in-file": "^7.2.0",
    "rxjs": "~7.8.1",
    "ssr-window": "^4.0.2",
    "tslib": "^2.7.0",
    "uuid": "^9.0.1",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.9",
    "@angular/cli": "^17.3.9",
    "@angular/compiler-cli": "^17.3.12",
    "@ngrx/schematics": "^17.2.0",
    "@types/express": "^4.17.21",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^20.16.5",
    "@types/uuid": "^9.0.8",
    "autoprefixer": "^10.4.20",
    "daisyui": "^4.12.10",
    "jasmine-core": "~5.3.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.45",
    "tailwindcss": "^3.4.11",
    "typescript": "~5.4.5"
  }
}
