import { Routes } from '@angular/router';

export const agencyCommissionsRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './agency-commissions-overview/agency-commissions-overview.component'
      ).then(c => c.AgencyCommissionsOverviewComponent),
  },
];
