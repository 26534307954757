import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PayoutsOverviewComponent } from 'src/app/components/payouts/payouts-overview/payouts-overview.component';
import { ModalComponent } from '../../ui-components/modal/modal.component';

@Component({
  selector: 'app-payouts-overview-modal',
  standalone: true,
  imports: [ModalComponent, PayoutsOverviewComponent],
  templateUrl: './payouts-overview-modal.component.html',
  styleUrl: './payouts-overview-modal.component.scss',
})
export class PayoutsOverviewModalComponent implements AfterViewInit {
  @Input() openWith?: {
    userId: string;
    identifierGroupId?: string;
    policyId?: string;
  };

  @Output() modalDismissedFired = new EventEmitter<boolean>();

  @ViewChild('modal') modal?: ModalComponent;

  policyId?: string;
  identifierGroupId?: string;
  userId?: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.openWith) {
      this.open(
        this.openWith.userId,
        this.openWith.identifierGroupId,
        this.openWith.policyId
      );
      this.cdr.detectChanges();
    }
  }

  open(userId: string, identifierGroupId?: string, policyId?: string) {
    console.log('open payout overview', { userId, identifierGroupId, policyId });
    this.policyId = policyId;
    this.identifierGroupId = identifierGroupId;
    this.userId = userId;

    this.modal?.show();
    this.cdr.detectChanges();
  }
}
